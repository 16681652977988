import { Component } from '@angular/core';

@Component({
  selector: 'water-dashboard-management-actions-panel',
  standalone: true,
  imports: [],
  templateUrl: './water-dashboard-management-actions-panel.component.html',
  styleUrl: './water-dashboard-management-actions-panel.component.scss'
})
export class WaterDashboardManagementActionsPanelComponent {

}
