<page-header icon="Geography" pageTitle="Geography Setup" [customRichTextTypeID]="richTextTypeID"></page-header>

<div class="page-body grid-12" *ngIf="currentUser$ | async">
    <app-alert-display></app-alert-display>

    <form class="form grid-12" #allocationPlanForm="ngForm" *ngIf="geography$ | async as geography">
        <div class="field g-col-10">
            <form-field [formControl]="formGroup.controls.GeographyDisplayName" fieldLabel="Geography Long Name" placeholder="e.g. Demo Geography"></form-field>
        </div>

        <div class="field g-col-4">
            <form-field [formControl]="formGroup.controls.StartYear" fieldLabel="Start Year" placeholder="e.g. 2016" mask="0000"></form-field>
        </div>

        <div class="field g-col-4">
            <form-field [formControl]="formGroup.controls.DefaultDisplayYear" fieldLabel="Default Display Year" placeholder="e.g. 2024" mask="0000"></form-field>
        </div>

        <div class="field g-col-5">
            <form-field [formControl]="formGroup.controls.APNRegexPattern" fieldLabel="APN Regex" placeholder="e.g. ^[0-9]{3}-[0-9]{3}-[0-9]{2}"></form-field>
        </div>

        <div class="field g-col-5">
            <form-field [formControl]="formGroup.controls.APNRegexDisplay" fieldLabel="APN Regex Display" placeholder="e.g. XXX-XXX-XX"></form-field>
        </div>

        <div class="field g-col-5">
            <form-field [formControl]="formGroup.controls.LandownerDashboardSupplyLabel" fieldLabel="Landowner Dashboard Supply Label" placeholder="e.g. Supply"></form-field>
        </div>

        <div class="field g-col-5">
            <form-field [formControl]="formGroup.controls.LandownerDashboardUsageLabel" fieldLabel="Landowner Dashboard Usage Label" placeholder="e.g. Usage"></form-field>
        </div>
        <div class="field g-col-5">
            <form-field [formControl]="formGroup.controls.ContactEmail" fieldLabel="Contact Email" placeholder="e.g. user@example.com"></form-field>
        </div>
        <div class="field g-col-5">
            <form-field
                [formControl]="formGroup.controls.ContactPhoneNumber"
                fieldLabel="Contact Phone Number"
                mask="(000) 000-0000"
                placeholder="e.g. (123) 456-7890"></form-field>
        </div>
        <div class="field g-col-5">
            <form-field
                [formControl]="formGroup.controls.DisplayUsageGeometriesAsField"
                [fieldLabel]="'Display Usage Geometries as Field'"
                [type]="FormFieldType.Toggle"></form-field>
        </div>
        <div class="field g-col-5">
            <!-- Empty field to keep the grid layout consistent -->
        </div>
        <div class="field g-col-5">
            <form-field
                [formControl]="formGroup.controls.AllowLandownersToRequestAccountChanges"
                [fieldLabel]="'Allow Landowners To Request Account Changes'"
                [type]="FormFieldType.Toggle"></form-field>
        </div>
    </form>

    <div class="card-footer flex-end pb-3" *ngIf="!isReadonly">
        <button type="submit" class="btn btn-primary" [disabled]="isLoadingSubmit" [buttonLoading]="isLoadingSubmit" (click)="onSubmit()">Save</button>
        <a class="btn btn-primary-outline ml-2" routerLink="..">Cancel</a>
    </div>
</div>
