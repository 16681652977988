<div class="nav-container">
  <div class="tab-nav">
    <a routerLink="/water-dashboard/water-accounts" class="tab" routerLinkActive="active">
      <icon icon="WaterAccounts"></icon>
      Water Accounts
    </a>
    <a routerLink="/water-dashboard/parcels" class="tab" routerLinkActive="active">
      <icon icon="Parcels"></icon>
      Parcels
    </a>
    <a routerLink="/water-dashboard/wells" class="tab" routerLinkActive="active">
      <icon icon="Wells"></icon>
      Wells
    </a>
  </div>
</div>