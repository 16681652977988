<page-header *ngIf="waterAccount$ | async as waterAccount" [pageTitle]="'#' + waterAccount.WaterAccountNumber" [templateTitleAppend]="templateTitleAppend"
    icon="WaterAccounts" preTitle="Wells">
    <ng-template #templateTitleAppend>
        <span class="water-account-name" *ngIf="waterAccount.WaterAccountName?.length > 0 && waterAccount.WaterAccountName != waterAccount.WaterAccountNumber"
            title="{{ waterAccount.WaterAccountName }}">
            {{ waterAccount.WaterAccountName }}
        </span>
    </ng-template>
</page-header>

<div class="page-body grid-12" *ngIf="wells$ | async as wells; else isLoadingTemplate">
    <ng-container *ngIf="wells.length > 0; else noWells">
        <div class="g-col-12">
            <qanat-map (onMapLoad)="handleMapReady($event)" mapHeight="700px">
                <ng-container *ngIf="mapIsReady">
                    <wells-layer [wells]="wells" [displayOnLoad]="true" [map]="map" (popupOpened)="onMapSelectionChanged($event)" [layerControl]="layerControl"
                        [highlightedWellID]="highlightedWellID"></wells-layer>
                </ng-container>
            </qanat-map>
        </div>
        <div class="g-col-12">
            <h3 class="module-title underline">All Wells</h3>
            <div class="table-wrapper">
                <qanat-grid [rowData]="wells" [columnDefs]="columnDefs" downloadFileName="parcels" rowSelection="single" (gridReady)="onGridReady($event)"
                    (selectionChanged)="onGridSelectionChanged($event)" height="500px" (filterChanged)="selectHighlightedWellIDRowNode()"
                    [pagination]="true"></qanat-grid>
            </div>
        </div>
    </ng-container>

    <ng-template #noWells>
        <div class="alert alert-info">
            <div class="alert-content">
                <i class="fa fa-info"></i>
                There are no wells currently associated with this water account.
            </div>
        </div>
    </ng-template>
</div>

<ng-template #isLoadingTemplate>
    <div [loadingSpinner]="{ isLoading: true }" style="margin-top: 10rem;">
    </div>
</ng-template>