<div class="parcel-popup popup-wrapper" [loadingSpinner]="{ isLoading, loadingHeight: 225 }">
    <ng-container *ngIf="parcel$ | async as parcel">
        <div class="popup-header">
            <name-tag [title]="parcel.GeographyDisplayName" [name]="parcel.GeographyName"></name-tag>
            <name-tag [title]="parcel.AllocationZoneGroupName + ': ' + parcel.AllocationZoneName" [name]="parcel.AllocationZoneName"
                [color]="parcel.AllocationZoneColor"></name-tag>
        </div>

        <div class="popup-body">
            <a [routerLink]="['/water-dashboard/parcels', parcel.ParcelID]">
                <parcel-title [parcelNumber]="parcel.ParcelNumber" [large]="true"></parcel-title>
            </a>

            <span class="acres">{{ parcel.ParcelArea | number: "1.2-2" }} acres</span>
            <a [routerLink]="['/water-accounts', parcel.WaterAccountID]">
                <water-account-title *ngIf="parcel.WaterAccountID" [waterAccountName]="parcel.WaterAccountName"
                    [waterAccountNumber]="parcel.WaterAccountNumber.toString()" [nameLength]="20" [small]="true"></water-account-title>
            </a>
        </div>
    </ng-container>
</div>