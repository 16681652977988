<div class="full-width-page">
    <page-header icon="Review" [customRichTextTypeID]="customRichTextId" [templateAbove]="templateAbove"></page-header>

    <ng-template #templateAbove>
        <a routerLink="/water-dashboard/wells" class="water-dashboard-link btn btn-sm btn-underline mb-4">
            <icon icon="ArrowLeft" class="mr-2"></icon>
            Back to All Wells
        </a>
    </ng-template>

    <div class="page-body">
        <app-alert-display></app-alert-display>
        <qanat-grid
            *ngIf="wellsToReview$ | async as wells"
            [rowData]="wells"
            [columnDefs]="colDefs"
            downloadFileName="submitted-wells"
            [colIDsToExclude]="['0']"
            [sizeColumnsToFitGrid]="true"></qanat-grid>
    </div>
</div>
