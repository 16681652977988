<div class="full-width-page">
    <page-header icon="WaterAccounts" [customRichTextTypeID]="customRichTextTypeID" pageTitle="Water Account Suggestions" [templateAbove]="templateAbove"></page-header>

    <ng-template #templateAbove>
        <a routerLink="/water-dashboard/water-accounts" class="water-dashboard-link btn btn-sm btn-underline mb-4">
            <icon icon="ArrowLeft" class="mr-2"></icon>
            Back to All Water Accounts
        </a>
    </ng-template>

    <div class="page-body" [loadingSpinner]="{ isLoading: isLoadingSuggestions }">
        <app-alert-display></app-alert-display>

        <section class="suggested-water-accounts">
            <ng-container *ngIf="waterAccountSuggestions">
                <h2 class="section-title suggested-water-accounts__title">
                    Suggested New Water Accounts
                    <span class="badge">{{ waterAccountSuggestions.length | number }}</span>
                </h2>

                <qanat-grid
                    [rowData]="waterAccountSuggestions"
                    [columnDefs]="columnDefs"
                    downloadFileName="suggested-water-accounts"
                    [colIDsToExclude]="colIDsToExclude"
                    rowSelection="multiple"
                    [suppressRowClickSelection]="true"
                    [pagination]="true"
                    [sizeColumnsToFitGrid]="true"
                    (gridReady)="onGridReady($event)"
                    (selectionChanged)="getSelectedRows($event)">
                    <div customGridActionsRight class="custom-grid-actions">
                        <button (click)="bulkApprove()" [buttonLoading]="isLoadingSubmit" class="btn btn-sm btn-success" [disabled]="isLoadingSubmit || selectedRows.length === 0">
                            Approve
                        </button>

                        <button (click)="bulkReject()" [buttonLoading]="isLoadingSubmit" class="btn btn-sm btn-danger" [disabled]="isLoadingSubmit || selectedRows.length === 0">
                            Reject
                        </button>
                    </div>
                </qanat-grid>
            </ng-container>
        </section>
    </div>
</div>
