/**
 * Qanat.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AdminGeographyUpdateRequestDto } from '../model/admin-geography-update-request-dto';
import { GeographyBoundarySimpleDto } from '../model/geography-boundary-simple-dto';
import { GeographyDto } from '../model/geography-dto';
import { GeographyUserDto } from '../model/geography-user-dto';
import { GeographyWithBoundingBoxDto } from '../model/geography-with-bounding-box-dto';
import { UploadUsageEntityGdbResponseDto } from '../model/upload-usage-entity-gdb-response-dto';
import { UserDto } from '../model/user-dto';
import { WaterDashboardGeographyDto } from '../model/water-dashboard-geography-dto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../../services';


@Injectable({
  providedIn: 'root'
})
export class GeographyService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration
    , private apiService: ApiService) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesCurrentUserGet(observe?: 'body', reportProgress?: boolean): Observable<Array<WaterDashboardGeographyDto>>;
    public geographiesCurrentUserGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WaterDashboardGeographyDto>>>;
    public geographiesCurrentUserGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WaterDashboardGeographyDto>>>;
    public geographiesCurrentUserGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<WaterDashboardGeographyDto>>(`${this.basePath}/geographies/current-user`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param adminGeographyUpdateRequestDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDEditPut(geographyID: number, adminGeographyUpdateRequestDto?: AdminGeographyUpdateRequestDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public geographiesGeographyIDEditPut(geographyID: number, adminGeographyUpdateRequestDto?: AdminGeographyUpdateRequestDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public geographiesGeographyIDEditPut(geographyID: number, adminGeographyUpdateRequestDto?: AdminGeographyUpdateRequestDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public geographiesGeographyIDEditPut(geographyID: number, adminGeographyUpdateRequestDto?: AdminGeographyUpdateRequestDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDEditPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/edit`,
            adminGeographyUpdateRequestDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param userDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDEditWaterManagersPut(geographyID: number, userDto?: Array<UserDto>, observe?: 'body', reportProgress?: boolean): Observable<GeographyDto>;
    public geographiesGeographyIDEditWaterManagersPut(geographyID: number, userDto?: Array<UserDto>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GeographyDto>>;
    public geographiesGeographyIDEditWaterManagersPut(geographyID: number, userDto?: Array<UserDto>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GeographyDto>>;
    public geographiesGeographyIDEditWaterManagersPut(geographyID: number, userDto?: Array<UserDto>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDEditWaterManagersPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<GeographyDto>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/edit-water-managers`,
            userDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDGet(geographyID: number, observe?: 'body', reportProgress?: boolean): Observable<GeographyDto>;
    public geographiesGeographyIDGet(geographyID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GeographyDto>>;
    public geographiesGeographyIDGet(geographyID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GeographyDto>>;
    public geographiesGeographyIDGet(geographyID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<GeographyDto>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDUploadUsageEntityGdbPost(geographyID: number, file?: Blob, observe?: 'body', reportProgress?: boolean): Observable<UploadUsageEntityGdbResponseDto>;
    public geographiesGeographyIDUploadUsageEntityGdbPost(geographyID: number, file?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UploadUsageEntityGdbResponseDto>>;
    public geographiesGeographyIDUploadUsageEntityGdbPost(geographyID: number, file?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UploadUsageEntityGdbResponseDto>>;
    public geographiesGeographyIDUploadUsageEntityGdbPost(geographyID: number, file?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDUploadUsageEntityGdbPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data',
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('File', <any>file) || formParams;
        }

        return this.httpClient.post<UploadUsageEntityGdbResponseDto>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/upload-usage-entity-gdb`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGsaBoundariesPut(observe?: 'body', reportProgress?: boolean): Observable<Array<GeographyBoundarySimpleDto>>;
    public geographiesGsaBoundariesPut(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<GeographyBoundarySimpleDto>>>;
    public geographiesGsaBoundariesPut(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<GeographyBoundarySimpleDto>>>;
    public geographiesGsaBoundariesPut(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.put<Array<GeographyBoundarySimpleDto>>(`${this.basePath}/geographies/gsa-boundaries`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyName 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographyGeographyNameEditGet(geographyName: string, observe?: 'body', reportProgress?: boolean): Observable<AdminGeographyUpdateRequestDto>;
    public geographyGeographyNameEditGet(geographyName: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AdminGeographyUpdateRequestDto>>;
    public geographyGeographyNameEditGet(geographyName: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AdminGeographyUpdateRequestDto>>;
    public geographyGeographyNameEditGet(geographyName: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyName === null || geographyName === undefined) {
            throw new Error('Required parameter geographyName was null or undefined when calling geographyGeographyNameEditGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<AdminGeographyUpdateRequestDto>(`${this.basePath}/geography/${encodeURIComponent(String(geographyName))}/edit`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public publicGeographiesGet(observe?: 'body', reportProgress?: boolean): Observable<Array<GeographyDto>>;
    public publicGeographiesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<GeographyDto>>>;
    public publicGeographiesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<GeographyDto>>>;
    public publicGeographiesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<GeographyDto>>(`${this.basePath}/public/geographies`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public publicGeographyBoundariesGet(observe?: 'body', reportProgress?: boolean): Observable<Array<GeographyBoundarySimpleDto>>;
    public publicGeographyBoundariesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<GeographyBoundarySimpleDto>>>;
    public publicGeographyBoundariesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<GeographyBoundarySimpleDto>>>;
    public publicGeographyBoundariesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<GeographyBoundarySimpleDto>>(`${this.basePath}/public/geographyBoundaries`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyName 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public publicGeographyBoundingBoxGeographyNameGet(geographyName: string, observe?: 'body', reportProgress?: boolean): Observable<GeographyWithBoundingBoxDto>;
    public publicGeographyBoundingBoxGeographyNameGet(geographyName: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GeographyWithBoundingBoxDto>>;
    public publicGeographyBoundingBoxGeographyNameGet(geographyName: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GeographyWithBoundingBoxDto>>;
    public publicGeographyBoundingBoxGeographyNameGet(geographyName: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyName === null || geographyName === undefined) {
            throw new Error('Required parameter geographyName was null or undefined when calling publicGeographyBoundingBoxGeographyNameGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<GeographyWithBoundingBoxDto>(`${this.basePath}/public/geography/boundingBox/${encodeURIComponent(String(geographyName))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyName 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public publicGeographyNameGeographyNameGet(geographyName: string, observe?: 'body', reportProgress?: boolean): Observable<GeographyDto>;
    public publicGeographyNameGeographyNameGet(geographyName: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GeographyDto>>;
    public publicGeographyNameGeographyNameGet(geographyName: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GeographyDto>>;
    public publicGeographyNameGeographyNameGet(geographyName: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyName === null || geographyName === undefined) {
            throw new Error('Required parameter geographyName was null or undefined when calling publicGeographyNameGeographyNameGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<GeographyDto>(`${this.basePath}/public/geography/name/${encodeURIComponent(String(geographyName))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param userID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userUserIDPermissionsGet(userID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<GeographyUserDto>>;
    public userUserIDPermissionsGet(userID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<GeographyUserDto>>>;
    public userUserIDPermissionsGet(userID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<GeographyUserDto>>>;
    public userUserIDPermissionsGet(userID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userID === null || userID === undefined) {
            throw new Error('Required parameter userID was null or undefined when calling userUserIDPermissionsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<GeographyUserDto>>(`${this.basePath}/user/${encodeURIComponent(String(userID))}/permissions`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

}
