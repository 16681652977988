<div class="card">
    <div class="card-header flex-start">
        <h3 class="card-title">Management Actions</h3>
        <div class="admin-pill">Admin</div>
    </div>

    <div class="card-body flex-start">
        <ng-content></ng-content>
    </div>
</div>
