<div *ngIf="currentUser$ | async as currentUser">
    <main class="main" *ngIf="parcel$ | async as parcel;">
        <page-header [templateRight]="templateRight" icon="Parcels" [pageTitle]="parcel.ParcelNumber">
            <ng-template #templateRight>
                <ng-container *ngIf="parcel.WaterAccount">
                    <a [routerLink]="['/water-dashboard', 'water-accounts', parcel.WaterAccount.WaterAccountID]">
                        <water-account-title [waterAccountName]="parcel.WaterAccount.WaterAccountName"
                            [waterAccountNumber]="parcel.WaterAccount.WaterAccountNumber.toString()"></water-account-title>
                    </a>
                </ng-container>
            </ng-template>
        </page-header>
        <div class="page-body statistics grid-12">
            <qanat-map (onMapLoad)="handleMapReady($event)" mapHeight="500px">
                <ng-container *ngIf="mapIsReady">
                    <water-account-parcels-layer *ngIf="parcel.WaterAccount" [displayOnLoad]="true" [map]="map" [geographyID]="parcel.GeographyID"
                        [waterAccountID]="parcel.WaterAccountID" [highlightedParcelIDs]="[parcel.ParcelID]"
                        [layerControl]="layerControl"></water-account-parcels-layer>

                    <highlighted-parcels-layer *ngIf="parcel.WaterAccountID === null" [displayOnLoad]="true" [map]="map" [geographyID]="parcel.GeographyID"
                        [highlightedParcelIDs]="[parcel.ParcelID]" [layerControl]="layerControl"></highlighted-parcels-layer>

                    <gsa-boundaries [map]="map" [layerControl]="layerControl" [geographyID]="parcel.GeographyID" [displayOnLoad]="false"></gsa-boundaries>

                    <zone-group-layer *ngFor="let zone of parcel.Zones" [displayOnLoad]="false" [zoneGroupID]="zone.ZoneGroupID"
                        [zoneGroupName]="zone.ZoneGroupName" [map]="map" [layerControl]="layerControl"></zone-group-layer>

                    <geography-external-map-layer *ngFor="let externalMapLayer of externalMapLayers$ | async" [map]="map" [layerControl]="layerControl"
                        [externalMapLayer]="externalMapLayer"></geography-external-map-layer>

                    <usage-entities-layer [map]="map" [layerControl]="layerControl" [parcelIDs]="[parcel.ParcelID]" [displayOnLoad]="true"
                        [geographyID]="parcel.GeographyID"></usage-entities-layer>
                </ng-container>
            </qanat-map>

            <div class="group group-3">
                <div class="statistics__module">
                    <div class="statistics__module-header">
                        <h3 class="statistics__title-small">Parcel Area</h3>
                    </div>

                    <div class="stat">
                        <h4 class="stat__value">
                            {{ parcel.ParcelArea | number: "1.2-2" }}
                        </h4>
                        <p class="stat__meta">acres</p>
                    </div>
                </div>

                <div class="statistics__module">
                    <div *ngIf="parcel.Zones.length > 0; else noZones">
                        <div *ngFor="let zone of parcel.Zones" class="pb-3">
                            <div class="statistics__module-header">
                                <h3 class="statistics__title-small">
                                    {{ zone.ZoneGroupName }}
                                </h3>
                            </div>

                            <div class="stat pb-2">
                                <h3>
                                    <span class="dot" [style]="getZoneColorStyle(zone.ZoneColor)"></span>
                                    {{ zone.ZoneName }}
                                </h3>
                            </div>
                        </div>
                    </div>
                    <ng-template #noZones>
                        <div class="stat pb-2">This parcel does not belong to any Zone Groups.</div>
                    </ng-template>
                    <a href="javascript:void(0);" (click)="editZoneAssignments()">
                        <icon icon="Zones"></icon>
                        Edit Zone Assignments
                    </a>
                </div>

                <div class="statistics__module">
                    <div class="statistics__module-header">
                        <h3 class="statistics__title-small">Owner Name</h3>
                    </div>
                    <div class="stat pb-3" *ngIf="parcel.OwnerName; else notAvailable">
                        <h4 class="stat__meta address">{{ parcel.OwnerName }}</h4>
                    </div>

                    <div class="statistics__module-header">
                        <h3 class="statistics__title-small">Owner Address</h3>
                    </div>
                    <div class="stat pb-3" *ngIf="parcel.OwnerAddress; else notAvailable">
                        <h4 class="stat__meta address">{{ parcel.OwnerAddress }}</h4>
                    </div>

                    <ng-template #notAvailable>
                        <div class="stat not-available pb-3">Not Available</div>
                    </ng-template>

                    <a href="javascript:void(0);" (click)="updateOwnershipInfo()">
                        <icon icon="Users"></icon>
                        Update Ownership Info
                    </a>
                </div>
            </div>

            <ng-container *ngIf="geography$ | async as geography">
                <div class="group" *ngIf="geography.DisplayUsageGeometriesAsField">
                    <div class="card">
                        <div class="card-header">
                            <h2 class="section-title">Fields</h2>
                        </div>
                        <div class="card-body">
                            <qanat-grid *ngIf="fields$ | async as fields" height="300px" [rowData]="fields" [columnDefs]="usageEntityColumnDefs"
                                downloadFileName="{{ parcel.ParcelNumber }}-fields"></qanat-grid>
                        </div>
                    </div>
                </div>
            </ng-container>

            <div id="water-measurements">
                <vega-parcel-usage-chart [parcel]="parcel"></vega-parcel-usage-chart>
            </div>

            <ng-container *ngIf="allocationPlans$ | async as allocationPlans">
                <div *ngIf="showAllocationPlan" class="card" id="allocation-plan">
                    <div class="card-header">
                        <h2 class="section-title">
                            <span *ngIf="selectedAllocationPlan">
                                {{ selectedAllocationPlan.Zone.ZoneName }}
                            </span>
                            Allocation Plan
                        </h2>
                    </div>
                    <div class="card-body">
                        <div *ngIf="selectedAllocationPlan.GeographyAllocationPlanConfiguration.AllocationPlansDescription as allocationPlansDescription"
                            class="copy copy-2 copy-user-entered mt-2 mb-3" [innerHtml]="allocationPlansDescription"></div>

                        <button-group class="mb-3 flex">
                            <button *ngFor="let allocationPlan of allocationPlans" (click)="selectedAllocationPlan = allocationPlan"
                                [class.active]="selectedAllocationPlan === allocationPlan">
                                {{ allocationPlan.WaterType.WaterTypeName }}
                            </button>
                        </button-group>
                        <allocation-plan-table [allocationPlan]="selectedAllocationPlan" class="mb-3"></allocation-plan-table>
                    </div>
                </div>
            </ng-container>

            <div class="card" id="ledger-activity">
                <div class="card-header">
                    <h2 class="section-title">Parcel Supply Activity</h2>
                </div>
                <div class="card-body">
                    <qanat-grid *ngIf="parcelSupplies$ | async as parcelSupplies" height="500px" [rowData]="parcelSupplies"
                        [columnDefs]="parcelSupplyGridColumnDefs" downloadFileName="{{ parcel.ParcelNumber }}-parcel-supply"></qanat-grid>
                    <div class="meta flex-end">
                        <div class="copy copy-3">
                            <note>
                                <p class="flex-between">
                                    <icon icon="Info"></icon>
                                    All transaction data is in units of acre-feet.
                                </p>
                            </note>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>

    <ng-template #isLoadingTemplate>
        <aside class="sidebar" [loadingSpinner]="{ isLoading: true }"></aside>
        <main class="main" [loadingSpinner]="{ isLoading: true }"></main>
    </ng-template>
</div>