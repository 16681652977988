/**
 * Qanat.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { MostRecentEffectiveDates } from '../model/most-recent-effective-dates';
import { ParcelActivityDto } from '../model/parcel-activity-dto';
import { ParcelSupplyUpsertDto } from '../model/parcel-supply-upsert-dto';
import { TransactionHistoryDto } from '../model/transaction-history-dto';
import { WaterAccountParcelWaterMeasurementDto } from '../model/water-account-parcel-water-measurement-dto';
import { WaterTypeSupplyDto } from '../model/water-type-supply-dto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../../services';


@Injectable({
  providedIn: 'root'
})
export class ParcelSupplyByGeographyService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration
    , private apiService: ApiService) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param geographyID 
     * @param parcelSupplyUpsertDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDParcelSuppliesBulkPost(geographyID: number, parcelSupplyUpsertDto?: ParcelSupplyUpsertDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public geographiesGeographyIDParcelSuppliesBulkPost(geographyID: number, parcelSupplyUpsertDto?: ParcelSupplyUpsertDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public geographiesGeographyIDParcelSuppliesBulkPost(geographyID: number, parcelSupplyUpsertDto?: ParcelSupplyUpsertDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public geographiesGeographyIDParcelSuppliesBulkPost(geographyID: number, parcelSupplyUpsertDto?: ParcelSupplyUpsertDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDParcelSuppliesBulkPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/parcel-supplies/bulk`,
            parcelSupplyUpsertDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param uploadedFile 
     * @param effectiveDate 
     * @param waterTypeID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDParcelSuppliesCsvPost(geographyID: number, uploadedFile: Blob, effectiveDate: string, waterTypeID: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public geographiesGeographyIDParcelSuppliesCsvPost(geographyID: number, uploadedFile: Blob, effectiveDate: string, waterTypeID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public geographiesGeographyIDParcelSuppliesCsvPost(geographyID: number, uploadedFile: Blob, effectiveDate: string, waterTypeID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public geographiesGeographyIDParcelSuppliesCsvPost(geographyID: number, uploadedFile: Blob, effectiveDate: string, waterTypeID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDParcelSuppliesCsvPost.');
        }

        if (uploadedFile === null || uploadedFile === undefined) {
            throw new Error('Required parameter uploadedFile was null or undefined when calling geographiesGeographyIDParcelSuppliesCsvPost.');
        }

        if (effectiveDate === null || effectiveDate === undefined) {
            throw new Error('Required parameter effectiveDate was null or undefined when calling geographiesGeographyIDParcelSuppliesCsvPost.');
        }

        if (waterTypeID === null || waterTypeID === undefined) {
            throw new Error('Required parameter waterTypeID was null or undefined when calling geographiesGeographyIDParcelSuppliesCsvPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data',
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (uploadedFile !== undefined) {
            formParams = formParams.append('UploadedFile', <any>uploadedFile) || formParams;
        }
        if (effectiveDate !== undefined) {
            formParams = formParams.append('EffectiveDate', <any>effectiveDate) || formParams;
        }
        if (waterTypeID !== undefined) {
            formParams = formParams.append('WaterTypeID', <any>waterTypeID) || formParams;
        }

        return this.httpClient.post<any>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/parcel-supplies/csv`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param year 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDParcelSuppliesMonthlyUsageSummaryYearYearGet(geographyID: number, year: number, observe?: 'body', reportProgress?: boolean): Observable<Array<WaterAccountParcelWaterMeasurementDto>>;
    public geographiesGeographyIDParcelSuppliesMonthlyUsageSummaryYearYearGet(geographyID: number, year: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WaterAccountParcelWaterMeasurementDto>>>;
    public geographiesGeographyIDParcelSuppliesMonthlyUsageSummaryYearYearGet(geographyID: number, year: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WaterAccountParcelWaterMeasurementDto>>>;
    public geographiesGeographyIDParcelSuppliesMonthlyUsageSummaryYearYearGet(geographyID: number, year: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDParcelSuppliesMonthlyUsageSummaryYearYearGet.');
        }

        if (year === null || year === undefined) {
            throw new Error('Required parameter year was null or undefined when calling geographiesGeographyIDParcelSuppliesMonthlyUsageSummaryYearYearGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<WaterAccountParcelWaterMeasurementDto>>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/parcel-supplies/monthly-usage-summary/year/${encodeURIComponent(String(year))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param parcelSupplyUpsertDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDParcelSuppliesPost(geographyID: number, parcelSupplyUpsertDto?: ParcelSupplyUpsertDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public geographiesGeographyIDParcelSuppliesPost(geographyID: number, parcelSupplyUpsertDto?: ParcelSupplyUpsertDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public geographiesGeographyIDParcelSuppliesPost(geographyID: number, parcelSupplyUpsertDto?: ParcelSupplyUpsertDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public geographiesGeographyIDParcelSuppliesPost(geographyID: number, parcelSupplyUpsertDto?: ParcelSupplyUpsertDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDParcelSuppliesPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/parcel-supplies`,
            parcelSupplyUpsertDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param year 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDParcelSuppliesRecentEffectiveDatesYearYearGet(geographyID: number, year: number, observe?: 'body', reportProgress?: boolean): Observable<MostRecentEffectiveDates>;
    public geographiesGeographyIDParcelSuppliesRecentEffectiveDatesYearYearGet(geographyID: number, year: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MostRecentEffectiveDates>>;
    public geographiesGeographyIDParcelSuppliesRecentEffectiveDatesYearYearGet(geographyID: number, year: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MostRecentEffectiveDates>>;
    public geographiesGeographyIDParcelSuppliesRecentEffectiveDatesYearYearGet(geographyID: number, year: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDParcelSuppliesRecentEffectiveDatesYearYearGet.');
        }

        if (year === null || year === undefined) {
            throw new Error('Required parameter year was null or undefined when calling geographiesGeographyIDParcelSuppliesRecentEffectiveDatesYearYearGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MostRecentEffectiveDates>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/parcel-supplies/recent-effective-dates/year/${encodeURIComponent(String(year))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDParcelSuppliesTransactionHistoryGet(geographyID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<TransactionHistoryDto>>;
    public geographiesGeographyIDParcelSuppliesTransactionHistoryGet(geographyID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TransactionHistoryDto>>>;
    public geographiesGeographyIDParcelSuppliesTransactionHistoryGet(geographyID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TransactionHistoryDto>>>;
    public geographiesGeographyIDParcelSuppliesTransactionHistoryGet(geographyID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDParcelSuppliesTransactionHistoryGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<TransactionHistoryDto>>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/parcel-supplies/transaction-history`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param year 
     * @param waterAccountID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDParcelSuppliesWaterAccountsWaterAccountIDRecentEffectiveDatesYearYearGet(geographyID: number, year: number, waterAccountID: number, observe?: 'body', reportProgress?: boolean): Observable<MostRecentEffectiveDates>;
    public geographiesGeographyIDParcelSuppliesWaterAccountsWaterAccountIDRecentEffectiveDatesYearYearGet(geographyID: number, year: number, waterAccountID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MostRecentEffectiveDates>>;
    public geographiesGeographyIDParcelSuppliesWaterAccountsWaterAccountIDRecentEffectiveDatesYearYearGet(geographyID: number, year: number, waterAccountID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MostRecentEffectiveDates>>;
    public geographiesGeographyIDParcelSuppliesWaterAccountsWaterAccountIDRecentEffectiveDatesYearYearGet(geographyID: number, year: number, waterAccountID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDParcelSuppliesWaterAccountsWaterAccountIDRecentEffectiveDatesYearYearGet.');
        }

        if (year === null || year === undefined) {
            throw new Error('Required parameter year was null or undefined when calling geographiesGeographyIDParcelSuppliesWaterAccountsWaterAccountIDRecentEffectiveDatesYearYearGet.');
        }

        if (waterAccountID === null || waterAccountID === undefined) {
            throw new Error('Required parameter waterAccountID was null or undefined when calling geographiesGeographyIDParcelSuppliesWaterAccountsWaterAccountIDRecentEffectiveDatesYearYearGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MostRecentEffectiveDates>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/parcel-supplies/water-accounts/${encodeURIComponent(String(waterAccountID))}/recent-effective-dates/year/${encodeURIComponent(String(year))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param waterAccountID 
     * @param year 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDParcelSuppliesWaterAccountsWaterAccountIDTransactionsYearYearGet(geographyID: number, waterAccountID: number, year: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ParcelActivityDto>>;
    public geographiesGeographyIDParcelSuppliesWaterAccountsWaterAccountIDTransactionsYearYearGet(geographyID: number, waterAccountID: number, year: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ParcelActivityDto>>>;
    public geographiesGeographyIDParcelSuppliesWaterAccountsWaterAccountIDTransactionsYearYearGet(geographyID: number, waterAccountID: number, year: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ParcelActivityDto>>>;
    public geographiesGeographyIDParcelSuppliesWaterAccountsWaterAccountIDTransactionsYearYearGet(geographyID: number, waterAccountID: number, year: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDParcelSuppliesWaterAccountsWaterAccountIDTransactionsYearYearGet.');
        }

        if (waterAccountID === null || waterAccountID === undefined) {
            throw new Error('Required parameter waterAccountID was null or undefined when calling geographiesGeographyIDParcelSuppliesWaterAccountsWaterAccountIDTransactionsYearYearGet.');
        }

        if (year === null || year === undefined) {
            throw new Error('Required parameter year was null or undefined when calling geographiesGeographyIDParcelSuppliesWaterAccountsWaterAccountIDTransactionsYearYearGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ParcelActivityDto>>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/parcel-supplies/water-accounts/${encodeURIComponent(String(waterAccountID))}/transactions/year/${encodeURIComponent(String(year))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param year 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDParcelSuppliesYearYearGet(geographyID: number, year: number, observe?: 'body', reportProgress?: boolean): Observable<Array<WaterTypeSupplyDto>>;
    public geographiesGeographyIDParcelSuppliesYearYearGet(geographyID: number, year: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WaterTypeSupplyDto>>>;
    public geographiesGeographyIDParcelSuppliesYearYearGet(geographyID: number, year: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WaterTypeSupplyDto>>>;
    public geographiesGeographyIDParcelSuppliesYearYearGet(geographyID: number, year: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDParcelSuppliesYearYearGet.');
        }

        if (year === null || year === undefined) {
            throw new Error('Required parameter year was null or undefined when calling geographiesGeographyIDParcelSuppliesYearYearGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<WaterTypeSupplyDto>>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/parcel-supplies/year/${encodeURIComponent(String(year))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

}
