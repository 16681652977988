/**
 * Qanat.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { GeographyLandingPageDto } from '../model/geography-landing-page-dto';
import { UnassignedUserReportDto } from '../model/unassigned-user-report-dto';
import { UserDetailedDto } from '../model/user-detailed-dto';
import { UserDto } from '../model/user-dto';
import { UserGeographySummaryDto } from '../model/user-geography-summary-dto';
import { UserUpsertDto } from '../model/user-upsert-dto';
import { WaterAccountParcelsRequestChangesDto } from '../model/water-account-parcels-request-changes-dto';
import { WellRegistrationMinimalDto } from '../model/well-registration-minimal-dto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../../services';


@Injectable({
  providedIn: 'root'
})
export class UserService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration
    , private apiService: ApiService) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param geographyID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDLandingPageGet(geographyID: number, observe?: 'body', reportProgress?: boolean): Observable<GeographyLandingPageDto>;
    public geographiesGeographyIDLandingPageGet(geographyID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GeographyLandingPageDto>>;
    public geographiesGeographyIDLandingPageGet(geographyID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GeographyLandingPageDto>>;
    public geographiesGeographyIDLandingPageGet(geographyID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDLandingPageGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<GeographyLandingPageDto>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/landing-page`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param userID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDUsersUserIDWaterAccountsGet(geographyID: number, userID: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public geographiesGeographyIDUsersUserIDWaterAccountsGet(geographyID: number, userID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public geographiesGeographyIDUsersUserIDWaterAccountsGet(geographyID: number, userID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public geographiesGeographyIDUsersUserIDWaterAccountsGet(geographyID: number, userID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDUsersUserIDWaterAccountsGet.');
        }

        if (userID === null || userID === undefined) {
            throw new Error('Required parameter userID was null or undefined when calling geographiesGeographyIDUsersUserIDWaterAccountsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/users/${encodeURIComponent(String(userID))}/water-accounts`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param userID 
     * @param waterAccountParcelsRequestChangesDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDUsersUserIDWaterAccountsPut(geographyID: number, userID: number, waterAccountParcelsRequestChangesDto?: WaterAccountParcelsRequestChangesDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public geographiesGeographyIDUsersUserIDWaterAccountsPut(geographyID: number, userID: number, waterAccountParcelsRequestChangesDto?: WaterAccountParcelsRequestChangesDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public geographiesGeographyIDUsersUserIDWaterAccountsPut(geographyID: number, userID: number, waterAccountParcelsRequestChangesDto?: WaterAccountParcelsRequestChangesDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public geographiesGeographyIDUsersUserIDWaterAccountsPut(geographyID: number, userID: number, waterAccountParcelsRequestChangesDto?: WaterAccountParcelsRequestChangesDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDUsersUserIDWaterAccountsPut.');
        }

        if (userID === null || userID === undefined) {
            throw new Error('Required parameter userID was null or undefined when calling geographiesGeographyIDUsersUserIDWaterAccountsPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/users/${encodeURIComponent(String(userID))}/water-accounts`,
            waterAccountParcelsRequestChangesDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pendingUsersGet(observe?: 'body', reportProgress?: boolean): Observable<Array<UserDto>>;
    public pendingUsersGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<UserDto>>>;
    public pendingUsersGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<UserDto>>>;
    public pendingUsersGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<UserDto>>(`${this.basePath}/pending-users`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userGeographySummaryGet(observe?: 'body', reportProgress?: boolean): Observable<Array<UserGeographySummaryDto>>;
    public userGeographySummaryGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<UserGeographySummaryDto>>>;
    public userGeographySummaryGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<UserGeographySummaryDto>>>;
    public userGeographySummaryGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<UserGeographySummaryDto>>(`${this.basePath}/user/geography-summary`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userWellRegistrationsGet(observe?: 'body', reportProgress?: boolean): Observable<Array<WellRegistrationMinimalDto>>;
    public userWellRegistrationsGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WellRegistrationMinimalDto>>>;
    public userWellRegistrationsGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WellRegistrationMinimalDto>>>;
    public userWellRegistrationsGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<WellRegistrationMinimalDto>>(`${this.basePath}/user/well-registrations`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public usersGet(observe?: 'body', reportProgress?: boolean): Observable<Array<UserDto>>;
    public usersGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<UserDto>>>;
    public usersGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<UserDto>>>;
    public usersGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<UserDto>>(`${this.basePath}/users`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public usersNormalUsersGet(observe?: 'body', reportProgress?: boolean): Observable<Array<UserDetailedDto>>;
    public usersNormalUsersGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<UserDetailedDto>>>;
    public usersNormalUsersGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<UserDetailedDto>>>;
    public usersNormalUsersGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<UserDetailedDto>>(`${this.basePath}/users/normal-users`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public usersUnassignedReportGet(observe?: 'body', reportProgress?: boolean): Observable<UnassignedUserReportDto>;
    public usersUnassignedReportGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UnassignedUserReportDto>>;
    public usersUnassignedReportGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UnassignedUserReportDto>>;
    public usersUnassignedReportGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<UnassignedUserReportDto>(`${this.basePath}/users/unassigned-report`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param userID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public usersUserIDGet(userID: number, observe?: 'body', reportProgress?: boolean): Observable<UserDto>;
    public usersUserIDGet(userID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserDto>>;
    public usersUserIDGet(userID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserDto>>;
    public usersUserIDGet(userID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userID === null || userID === undefined) {
            throw new Error('Required parameter userID was null or undefined when calling usersUserIDGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<UserDto>(`${this.basePath}/users/${encodeURIComponent(String(userID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param userID 
     * @param userUpsertDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public usersUserIDPut(userID: number, userUpsertDto?: UserUpsertDto, observe?: 'body', reportProgress?: boolean): Observable<UserDto>;
    public usersUserIDPut(userID: number, userUpsertDto?: UserUpsertDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserDto>>;
    public usersUserIDPut(userID: number, userUpsertDto?: UserUpsertDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserDto>>;
    public usersUserIDPut(userID: number, userUpsertDto?: UserUpsertDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userID === null || userID === undefined) {
            throw new Error('Required parameter userID was null or undefined when calling usersUserIDPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<UserDto>(`${this.basePath}/users/${encodeURIComponent(String(userID))}`,
            userUpsertDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param userID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public usersUserIDWellRegistrationsGet(userID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<WellRegistrationMinimalDto>>;
    public usersUserIDWellRegistrationsGet(userID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WellRegistrationMinimalDto>>>;
    public usersUserIDWellRegistrationsGet(userID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WellRegistrationMinimalDto>>>;
    public usersUserIDWellRegistrationsGet(userID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userID === null || userID === undefined) {
            throw new Error('Required parameter userID was null or undefined when calling usersUserIDWellRegistrationsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<WellRegistrationMinimalDto>>(`${this.basePath}/users/${encodeURIComponent(String(userID))}/well-registrations`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

}
