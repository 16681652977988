/**
 * Qanat.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { GeographySimpleDto } from '././geography-simple-dto';
import { WaterAccountUserMinimalDto } from '././water-account-user-minimal-dto';
import { ParcelDisplayDto } from '././parcel-display-dto';

import { FormControl, FormControlOptions, FormControlState, Validators } from "@angular/forms";
export class WaterAccountDto { 
    WaterAccountID?: number;
    Geography?: GeographySimpleDto;
    WaterAccountNumber?: number;
    WaterAccountName?: string;
    Notes?: string;
    UpdateDate?: string;
    WaterAccountPIN?: string;
    WaterAccountPINLastUsed?: string;
    CreateDate?: string;
    ContactName?: string;
    ContactAddress?: string;
    Users?: Array<WaterAccountUserMinimalDto>;
    Parcels?: Array<ParcelDisplayDto>;
    WaterAccountNameAndNumber?: string;
    constructor(obj?: any) {
        Object.assign(this, obj);
    }
}

export interface WaterAccountDtoForm { 
    WaterAccountID?: FormControl<number>;
    Geography?: FormControl<GeographySimpleDto>;
    WaterAccountNumber?: FormControl<number>;
    WaterAccountName?: FormControl<string>;
    Notes?: FormControl<string>;
    UpdateDate?: FormControl<string>;
    WaterAccountPIN?: FormControl<string>;
    WaterAccountPINLastUsed?: FormControl<string>;
    CreateDate?: FormControl<string>;
    ContactName?: FormControl<string>;
    ContactAddress?: FormControl<string>;
    Users?: FormControl<Array<WaterAccountUserMinimalDto>>;
    Parcels?: FormControl<Array<ParcelDisplayDto>>;
    WaterAccountNameAndNumber?: FormControl<string>;
}

export class WaterAccountDtoFormControls { 
    public static WaterAccountID = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static Geography = (value: FormControlState<GeographySimpleDto> | GeographySimpleDto = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<GeographySimpleDto>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static WaterAccountNumber = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static WaterAccountName = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static Notes = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static UpdateDate = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static WaterAccountPIN = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static WaterAccountPINLastUsed = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static CreateDate = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static ContactName = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static ContactAddress = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static Users = (value: FormControlState<Array<WaterAccountUserMinimalDto>> | Array<WaterAccountUserMinimalDto> = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<Array<WaterAccountUserMinimalDto>>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static Parcels = (value: FormControlState<Array<ParcelDisplayDto>> | Array<ParcelDisplayDto> = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<Array<ParcelDisplayDto>>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static WaterAccountNameAndNumber = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
}
